import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedCategories: []
}

export const bookstoreSlice = createSlice({
  name: 'bookstore',
  initialState,
  reducers: {
    saveCategories: (state, action) => {
      return action.payload
    },
    clearCategories: () => {
      return initialState
    }
  }
})

// Action creators are generated for each case reducer function
export const { saveCategories, clearCategories } = bookstoreSlice.actions

export default bookstoreSlice.reducer