import { configureStore } from '@reduxjs/toolkit'
import { loadState, saveState } from './localStorage'
import cartReducer from './cartSlice'
import bookstoreReducer from './bookstoreSlice'

const preloadedState = loadState()

const store = configureStore({
    reducer: {
        cart: cartReducer,
        bookstore: bookstoreReducer,
    },
    preloadedState
})

store.subscribe(() => {
    saveState(store.getState())
})

export default store