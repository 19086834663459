import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      let item = action.payload;
      if (!item.count) item.count = 1
      // item.total = item.price

      if (state.find(cartItem => cartItem.sku === item.sku)) {
        state.map(cartItem => {
          if (cartItem.sku === action.payload.sku) {
            cartItem.count++
          }
          return cartItem
        })
      } else {
        return [...state, item]
      }
    },
    deleteFromCart: (state, action) => {
      return state.filter(cartItem => cartItem.sku !== action.payload.sku)
    },
    changeQuantity: (state, action) => {
      const count = action.payload.count
      return state.map(cartItem => {
        if (cartItem.sku !== action.payload.sku) return cartItem
        return {...cartItem, count}
      }).filter(cartItem => cartItem.count > 0)
    },
    clearCart: () => {
      return initialState
    },
    alignWithCms: (state, action) => {
      const cmsItems = action.payload
      return state.map(cartItem => {
        const i = cmsItems.findIndex(e => e.sku === cartItem.sku)
        if (i > -1) {
          return {...cartItem, ...cmsItems[i]}
        }
        return cartItem
      })
    }
  }
})

// Action creators are generated for each case reducer function
export const { addToCart, deleteFromCart, changeQuantity, clearCart, alignWithCms } = cartSlice.actions

export default cartSlice.reducer