const isWindow = typeof window !== 'undefined'
const isLocalStorage = isWindow && localStorage === window.localStorage

export const loadState = () => {
  try {
    const stateObjStr = isLocalStorage && localStorage.getItem('state')
    if (!stateObjStr) {
      return undefined
    }
    const stateObj = JSON.parse(stateObjStr)
    const now = new Date()
    if (now.getTime() > stateObj.expiresOn) {
      localStorage.removeItem('state')
      return undefined
    }
    return stateObj.value
  } catch (error) {
    console.error('Failed to load state: ', error)
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const now = new Date()
    const ttl = 24 * 60 * 60 * 1000 // 24 hours
    const stateObj = {
      value: state,
      expiresOn: now.getTime() + ttl
    }
    localStorage.setItem('state', JSON.stringify(stateObj))
  } catch (error) {
    console.error('Failed to save state: ', error)
  }
}
